import React, { useState, useEffect, useLayoutEffect } from 'react';
import useForm from 'react-hook-form';
import Axios from 'axios';
import { navigate } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import FormButton from '../components/buttons/form-button';
import SEO from '../components/seo';
import { logger } from '../services/logger';
import './lead-form.css';
import { getGoogleClickIdentifier } from '../services/google-click-identifier';
import { VisitorData } from '../services/visitor-info';

const GetPricing = () => {
  const data = useStaticQuery(graphql`
    query {
      scannerKiosk: file(relativePath: { eq: "feature-images/scanner-form.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const { register, handleSubmit } = useForm();

  const [gclid, setGCLID] = useState(undefined);
  const [visitId, setVisitId] = useState('undefined');
  const [visitCity, setVisitCity] = useState('undefined');
  const [visitState, setVisitState] = useState('undefined');

  useEffect(() => {
    setTimeout(() => {
      // Get the Google Click Identifier after a small delay to allow it to be loaded
      setGCLID(getGoogleClickIdentifier());
      const visitData: VisitorData = JSON.parse(localStorage.getItem('vid'));
      if (visitData) {
        setVisitId(visitData.clientID);
        setVisitCity(visitData.city);
        setVisitState(visitData.state);
        console.log(`visitID ${visitId}, city: ${visitCity}, state: ${visitState}`);
      }
    }, 100);
  }, []);

  const submitForm = (data: any) => {
    if (typeof window !== 'undefined') {
      const anyWindow = window as any;
      if (anyWindow.fbq != null) {
        anyWindow.fbq('track', 'Lead');
      }
    }
    logger('PricingRequest', 'submission', 'inbounds');
    const formData = {
      name: data.name,
      organization: data.organization,
      email: data.email,
      phone: data.phone,
      country: data.country,
      your_industry: data.industry,
      message: data.message,
      lead_page: 'Get Pricing',
      gclid: data.gclid,
      visitId: data.visitId,
      city: data.city,
      state: data.state,
    };
    Axios.post(
      'https://prod-01.canadacentral.logic.azure.com:443/workflows/844eec674e744991b5309933956c9f36/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=pkYa_Lwa_a8jnpMqdOa3un2ixjx_XI4zd_zvcL0JIts',
      formData,
    ).then((data: any) => navigate('/pricing-thanks'));
  };

  return (
    <div className="lead-page-container">
      <SEO title="Get Pricing - Patronscan" />
      <div className="get-pricing-container">
        <div className="lead-form-appeal">
          <h1 className="lead-tagline-1 font-subheading thin-heading">
            Protect your business with trusted ID verification.
          </h1>
          <div className="lead-form-image-container">
            <Img fluid={data.scannerKiosk.childImageSharp.fluid} />
          </div>
        </div>
        <div className="lead-form-container">
          <div className="lead-form">
            <div className="lead-form-tagline font-subheading">
              <h2>Get Pricing</h2>
            </div>
            <form onSubmit={handleSubmit(submitForm)}>
              <div className="inline-flex flex-wrap w-full form-field">
                <label className="w-full block form-text-base mb-2 form-label" htmlFor="email">
                  Email*
                </label>
                <input
                  className="border-blue p-2 rounded-lg border block form-text-base form-input-field"
                  placeholder="Email*"
                  id="email"
                  name="email"
                  type="email"
                  ref={register({ required: true })}
                />
              </div>
              <div className="inline-flex flex-wrap w-full form-field">
                <label className="w-full block form-text-base mb-2 form-label" htmlFor="name">
                  Name*
                </label>
                <input
                  className="border-blue p-2 rounded-lg border block form-text-base form-input-field"
                  placeholder="Name*"
                  id="name"
                  name="name"
                  type="text"
                  ref={register({ required: true })}
                />
              </div>
              <div className="inline-flex flex-wrap w-full form-field">
                <label
                  className="w-full block form-text-base mb-2 form-label"
                  htmlFor="organization"
                >
                  Company
                </label>
                <input
                  className="border-blue p-2 rounded-lg border block form-text-base form-input-field"
                  placeholder="Business Name"
                  id="organization"
                  name="organization"
                  type="text"
                  ref={register({ required: false })}
                />
              </div>
              <div className="inline-flex flex-wrap w-full form-field">
                <label className="w-full block form-text-base mb-2 form-label" htmlFor="phone">
                  Phone Number
                </label>
                <input
                  className="border-blue p-2 rounded-lg border block form-text-base form-input-field"
                  placeholder="Phone Number"
                  id="phone"
                  name="phone"
                  type="phone"
                  ref={register({ required: false })}
                />
              </div>
              <div className="inline-flex flex-wrap w-full form-field">
                <label className="w-full block form-text-base mb-2 form-label" htmlFor="message">
                  Anything Else You'd Like Us To Know?
                </label>
                <textarea
                  className="border-blue p-2 rounded-lg border block form-text-base form-input-field"
                  id="message"
                  placeholder="Anything Else You'd Like Us To Know?"
                  name="message"
                  ref={register}
                ></textarea>
              </div>
              <div className="inline-flex flex-wrap w-full form-field">
                <label className="w-full block form-text-base mb-2 form-label" htmlFor="industry">
                  Your Industry
                </label>
                <select
                  className="border-blue p-2 rounded-lg border block form-text-base form-input-field"
                  name="industry"
                  id="industry"
                  ref={register({ required: true })}
                >
                  <option selected hidden className="option-select">
                    Select Industry
                  </option>
                  <option value="Bar & Nightclub">Bar & Nightclub</option>
                  <option value="Property Management">Property Management</option>
                  <option value="Liquor Store">Liquor Store</option>
                  <option value="Access Control">Access Control</option>
                  <option value="Retail Store">Retail</option>
                  <option value="Gaming">Gaming</option>
                  <option value="Telecom">Telecom</option>
                  <option value="Hotel">Hotel</option>
                  <option value="Automotive">Automotive</option>
                  <option value="Retail Cannabis">Cannabis</option>
                  <option value="Retail Vaping">Vaping</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="inline-flex flex-wrap w-full form-field">
                <label className="w-full block form-text-base mb-2 form-label" htmlFor="country">
                  Country
                </label>
                <select
                  className="border-blue p-2 rounded-lg border block form-text-base form-input-field"
                  name="country"
                  id="country"
                  ref={register({ required: false })}
                >
                  <option selected hidden>
                    Select Country
                  </option>
                  <option value="Australia">Australia</option>
                  <option value="Canada">Canada</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <input
                id="gclid"
                name="gclid"
                value={gclid}
                type="hidden"
                ref={register({ required: false })}
              />
              <input
                id="visitId"
                name="visitId"
                value={visitId}
                type="hidden"
                ref={register({ required: false })}
              />
              <input
                id="city"
                name="city"
                value={visitCity}
                type="hidden"
                ref={register({ required: false })}
              />
              <input
                id="state"
                name="state"
                value={visitState}
                type="hidden"
                ref={register({ required: false })}
              />
              <div className="">
                <FormButton
                  formId="GetPricing"
                  className="form-submit-button"
                  text="Submit"
                  type="submit"
                />
                <img
                  className="hidden height: 1px width: 1px"
                  src="https://px.ads.linkedin.com/collect/?pid=2825404&conversionId=3295700&fmt=gif"
                />
              </div>
            </form>
          </div>
          <div className="submission-button"></div>
        </div>
      </div>
    </div>
  );
};

export default GetPricing;
